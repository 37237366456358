import React, { Component, useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CustomNumberInput from "components/number-input";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import { netGet, netPost } from '../network';


function RevisionRecordConfig (props) {
    const {
        doc,
        onChange,
        saveEnabled,
        readOnly,
        ...other
    } = props;

    const [limited, setLimited] = useState(true);
    const [numRecords, setNumRecords] = useState(100);
    const [configKey, setConfigKey] = useState('');

    useEffect( () => {
            if ( doc ) {
                const key = `${doc['configBaseKey']}.workflow.revision-record.numberOfRecords`;
                setConfigKey(key);
                // console.log('RR: key = ' + JSON.stringify(key));
                const url = `/api/config/vget?key=${key}`;
                
                netGet(url)
                    .then(response => response.json())
                    .then(num => {
                        // console.log('RR stored: ' + (typeof num?.v) + ' ' + JSON.stringify(num));
                        if ( typeof num.v === 'string' && num.v.length === 0 ) {
                            setLimited(true);
                            setNumRecords(100);
                        } else if ( typeof num.v === 'number' || typeof num.v === 'string' ) {
                            const value = typeof num.v === 'number' ? num.v : Number.parseInt(num.v);
                            setNumRecords(value > 0 ? value : 2);
                            setLimited( value > 0);
                        } else {
                            setLimited(true);
                            setNumRecords(100);
                        }
                        
                        
                    }).catch(error => {
                        console.log('Error fetching download profiles: ' + error);
                        setLimited(true);
                        setNumRecords(100);
                    });
                
            } else {
                setNumRecords(100);
                setLimited(true);
            }
    
        },[doc,readOnly]);

    const onLimitedChanged = (value) => {
        if ( readOnly) {
            return;
        }
        setLimited(value);
        if ( value ) {
            if ( numRecords < 2 ) {
                setNumRecords(2);
            }
        } else {
            setNumRecords(-1);
        }
        onConfigChange(value, numRecords);
    };

    const onNumberRecordsChanged = (value) => {
        if ( readOnly ) {
            return;
        }
        setNumRecords(value);
        onConfigChange(limited, value);
    }

    const onConfigChange = (lim, num) => {
        if ( readOnly ) {
            return;
        }
        const value = lim ? num : -1;

        netPost(`/api/config/set?key=${configKey}`, `${value}`)
            .then(resp => {
                if (!resp.ok) {
                    console.log('Could not save rules: status = ' + resp.status);
                } else {
                    // delete def.saveSteps;
                    // setSaveDocumentEnabled(false);
                    // handleItemClick(selectedIndex);
                    // unmarkModified();
                }
            });
        if (typeof onChange === 'function') {
            onChange(value);
        }
    }


    return (
        <Box>
            <Typography sx={{ marginLeft: 2, marginRight: 2, marginTop: 3, fontStyle: 'italic', fontWeight: 'bold' , color: '#202020', fontSize: '105%' }}>Maximum number of records: </Typography>
            <Box sx={{ paddingTop: '2ex', display: 'flex', alignItems: 'baseline'}}>
                <FormControlLabel
                    sx={{
                        marginLeft: 3,
                        marginTop: 2,
                    }}
                    control={<Switch checked={limited} sx={{

                    }} size="small" disabled={readOnly}
                        disableTypography={false}
                        onChange={(event) => onLimitedChanged(event.target.checked)} />}
                    label={limited ? <Typography sx={{ fontWeight: 'bold' }}>Limited</Typography> : <Typography sx={{ fontWeight: 'bold' }}>Unlimited</Typography>} />
                {limited &&
                    <CustomNumberInput size="small" value={numRecords} onChange={(event,val) => onNumberRecordsChanged(val)} sx={{marginLeft: 2}} min={2} disabled={readOnly}/>
                }
                
            </Box>
            {/* limited &&
            <Typography sx={{ marginLeft: 3, marginTop: 4, fontSize: '90%', color: '#404040'}}>{`The revision record table will be limited to the last ${numRecords} entr`}{numRecords === 1 ? 'y.' : 'ies.'}</Typography>
            */}
        </Box>
    );

}

export { RevisionRecordConfig };